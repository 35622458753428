
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { aimService } from "@/services/AIMService";
import { useI18n } from "vue-i18n";

import VSection from "@/components/atoms/VSection/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";
import VLoadSpinner from "@/components/organisms/VLoadSpinner/index.vue";
import VHeading from "@/components/atoms/VHeading/index.vue";
import VQuestionnaireButton from "@/components/molecules/VQuestionnaireButton/index.vue";

import { useStore } from "@/store";
import { Questionnaire } from "@/types/objects";

import { computed } from "@vue/reactivity";

export default defineComponent({
  name: "HomeView",
  components: {
    VSection,
    VButton,
    VLoadSpinner,
    VHeading,
    VQuestionnaireButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t, locale } = useI18n({ useScope: "global" });

    const isLoading = ref(false);
    const error = ref();
    const questionnaires = ref<Questionnaire[]>();

    const isLoggedIn = async () => {
      return await aimService.isLoggedIn();
    };

    const getQuestionnaires = async () => {
      isLoading.value = true;
      // await new Promise((r) => setTimeout(r, 2000));
      if (await isLoggedIn()) {
        try {
          store.dispatch("loadAllQuestionnairesFromApi");
          questionnaires.value = store.state.questionnaires;
          isLoading.value = false;
        } catch (e) {
          error.value = e;
          isLoading.value = false;
        }
      }
    };

    const getTitle = (input: Record<string, unknown> | string) => {
      if (typeof input === "string") {
        return input;
      } else if (input instanceof Object) {
        if (
          Object.prototype.hasOwnProperty.call(input, locale.value as string)
        ) {
          return input[locale.value as string];
        } else {
          return input["default"]; //"default" is english
        }
      }
    };

    const toQuestionnaireStart = async (questionnaire: Questionnaire) => {
      await store.dispatch("setActiveQuestionnaire", questionnaire);
      await router.push(`/questionnaire/${questionnaire.uuid}`);
    };

    onMounted(() => {
      store.dispatch("removeAllQuestionnaires");
      getQuestionnaires();
    });

    return {
      t,
      getTitle,
      toQuestionnaireStart,
      getQuestionnaires,
      isLoading,
      error,
      questionnaires,
      loadingText: computed(() => t("loadingQuestionnaires")),
    };
  },
});
