import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "title",
  class: "font-bold text-lg"
}
const _hoisted_2 = {
  key: 0,
  id: "isAdmin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.user.email), 1),
    (_ctx.user.isAdmin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t("admin")), 1))
      : _createCommentVNode("", true)
  ], 2))
}