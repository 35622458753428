import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-center"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyJSContainer = _resolveComponent("SurveyJSContainer")!
  const _component_v_section = _resolveComponent("v-section")!

  return (_openBlock(), _createBlock(_component_v_section, null, {
    default: _withCtx(() => [
      (_ctx.currentQuestionnaire)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_SurveyJSContainer, {
              questionnaire: _ctx.currentQuestionnaire,
              onOnComplete: _ctx.onComplete
            }, null, 8, ["questionnaire", "onOnComplete"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t("noQuestionnaire")), 1))
    ]),
    _: 1
  }))
}