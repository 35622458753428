
import { defineComponent } from "vue";

export type IconKey = 1 | 2 | 3;

export default defineComponent({
  name: "VResultIcon",
  props: {
    icon: Number,
  },
});
