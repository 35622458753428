import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-semibold text-4xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_section = _resolveComponent("v-section")!

  return (_openBlock(), _createBlock(_component_v_section, { class: "flex flex-row content-center" }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t("welcome")), 1)
    ]),
    _: 1
  }))
}