import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "locale_switcher",
  class: "lang-dropdown"
}
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      id: "locale_switcher_select",
      class: "py-2 px-4 tracking-wider rounded-lg border-2 border-transparent bg-transparent inline-flex items-center justify-center space-x-2 font-bold uppercase focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-60 hover:cursor-pointer hover:bg-white hover:text-primary",
      role: "menu",
      "aria-label": _ctx.title,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageArray, (lang, i) => {
        return (_openBlock(), _createElementBlock("option", {
          role: "menuitem",
          key: `lang${i}`,
          value: lang
        }, _toDisplayString(lang), 9, _hoisted_3))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.locale]
    ])
  ]))
}