import {
  IndicatorOutput,
  Questionnaire,
  User,
  UserResultReference,
} from "@/types/objects";

export interface State {
  id: string;
  questionnaires: Questionnaire[];
  results: UserResultReference[]; //TODO properly define
  users: User[];
  activeQuestionnaire: Questionnaire | undefined;
  activeResults: IndicatorOutput | undefined;
  activeResultAnswers: Record<string, unknown> | undefined;
}

export const state: State = {
  id: "",
  questionnaires: [],
  results: [],
  users: [],
  activeQuestionnaire: undefined,
  activeResults: undefined,
  activeResultAnswers: undefined,
};
