
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import {
  useRoute,
  useRouter,
  RouterLink,
  onBeforeRouteUpdate,
} from "vue-router";

import VSection from "@/components/atoms/VSection/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";
import VLoadSpinner from "@/components/organisms/VLoadSpinner/index.vue";
import VHeading from "@/components/atoms/VHeading/index.vue";
import VResultIcon from "@/components/atoms/VResultIcon/index.vue";

import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { IndicatorOutput, TranslationString } from "@/types/objects";

import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";

export default defineComponent({
  name: "ResultsView",
  components: {
    VSection,
    VButton,
    VLoadSpinner,
    VHeading,
    VResultIcon,
    RouterLink,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const error = ref();

    const resultsUuid = ref(route.params.id);
    const indicatorOutput = ref<IndicatorOutput>();
    const finalResultUuid = ref<string>();

    const getResults = async () => {
      isLoading.value = true;
      try {
        await store.dispatch("loadResultsFromApi", resultsUuid.value);
        indicatorOutput.value = store.getters.activeResults;
      } catch (e) {
        error.value = e;
        isLoading.value = false;
      }
      if (indicatorOutput.value) {
        isLoading.value = false;
      } else {
        error.value = "No results found";
        isLoading.value = false;
      }
    };

    const getFinalResultUuid = async (resultGroupUuid: string) => {
      isLoading.value = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ADDR}/resultGroups/finalResult/${resultGroupUuid}`
        );
        finalResultUuid.value = response.data;
      } catch (e) {
        error.value = e;
        isLoading.value = false;
      }
      isLoading.value = false;
      // if (finalResultUuid.value) {
      //   isLoading.value = false;
      // } else {
      //   error.value = "No final result data found";
      //   isLoading.value = false;
      // }
    };

    const getTranslatedString = (input: TranslationString | string) => {
      if (typeof input == "string") {
        return input;
      }
      type ObjectKey = keyof typeof input;
      try {
        const lang = locale.value as ObjectKey;
        return input[lang];
      } catch (e) {
        return input["en"];
      }
    };

    const hasUserTitle = computed(() => {
      return (
        indicatorOutput.value?.userTitle &&
        indicatorOutput.value?.userTitle != " - undefined"
      );
    });

    // Helper function that enables in-line hyperlinks to appear formatted on results page
    const displayAllText = () => {
      // Indicators
      indicatorOutput.value?.indicatorValues.forEach((indicator) => {
        const resultText: string = indicator.notes
          ? getTranslatedString(indicator.notes)
          : "";
        const elem = document.querySelector(
          `#${`indicator_notes_${indicator.title}`}`
        );
        if (elem != null) elem.innerHTML = resultText;
      });

      // Recommendations
      indicatorOutput.value?.recommendations.forEach((recommendation) => {
        const resultText: string = recommendation.notes
          ? getTranslatedString(recommendation.notes)
          : "";
        const elem = document.querySelector(
          `#${`findings_and_recommendations_${recommendation.title}`}`
        );
        if (elem != null) elem.innerHTML = resultText;
      });

      // Getting Started
      indicatorOutput.value?.gettingStarted.forEach((gsItem) => {
        const resultText: string = gsItem.notes
          ? getTranslatedString(gsItem.notes)
          : "";
        const elem = document.querySelector(
          `#${`getting_started_${gsItem.title}`}`
        );
        if (elem != null) elem.innerHTML = resultText;
      });
    };

    const toAnswers = () => {
      router.push(`/questionnaireReview/${resultsUuid.value}`);
    };

    const toFinalResult = () => {
      router.push(`/results/${finalResultUuid.value}`);
    };

    /**
     * This trigger is to properly re-render the innerHTML of certain translated
     * texts on-screen, because we store formatting as pure HTML :(
     *
     * Ideally we handle all formatting on the client-side, and just send
     * properties like `URLText` or `bold`.
     */
    watch(locale, (value, prevValue) => {
      displayAllText();
    });

    onBeforeRouteUpdate(async (to, from) => {
      resultsUuid.value = to.params.id;
      await getResults();
      if (indicatorOutput.value)
        await getFinalResultUuid(indicatorOutput.value.resultGroupUuid);
      displayAllText();
    });

    onMounted(async () => {
      await getResults();
      if (indicatorOutput.value)
        await getFinalResultUuid(indicatorOutput.value.resultGroupUuid);
      displayAllText();
    });

    const savePDF = () => {
      // const doc = new jsPDF({
      //   orientation: "portrait",
      // });
      // html2canvas(document.querySelector("#results_page") as HTMLElement).then(
      //   (canvas) => {
      //     const img = canvas.toDataURL("image/jpeg", 1);
      //     doc.addImage(img, "JPEG", 0, 0, 200, 280);
      //     doc.save("test.pdf");
      //   }
      // );
      const doc = new jsPDF();

      // const headers = [];
      // indicatorOutput.value?.indicatorValues.forEach((indicatorValue) => {
      //   headers.push({
      //     id: indicatorValue.title,
      //     name: getTranslatedString(indicatorValue.titles),
      //     prompt: indicatorValue.title,
      //     width: 65,
      //     align: "center",
      //     padding: 0,
      //   });
      // });

      const filename = `results - ${indicatorOutput.value?.questionnaireTitle}.pdf`;
      doc.save(filename);
    };

    return {
      t,
      getResults,
      getTranslatedString,
      resultsUuid,
      isLoading,
      error,
      indicatorOutput,
      finalResultUuid,
      loadingText: computed(() => t("loadingResults")),
      toAnswers,
      toFinalResult,
      hasUserTitle,
      savePDF,
    };
  },
});
