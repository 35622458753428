
import { defineComponent, PropType, computed } from "vue";
import type { HeadingRank } from "@/types/ui";

const h1Classes = `text-5xl mt-10 mb-6 flex items-center justify-start gap-x-2`;
const h2Classes = `text-4xl mt-8 mb-6`;
const h3Classes = `text-3xl mt-8 mb-6`;
const h4Classes = `text-2xl mt-6 mb-4`;
const h5Classes = `text-xl mt-6 mb-4`;
const h6Classes = `text-lg my-4`;

const rankClasses = new Map<HeadingRank, string>([
  ["h1", h1Classes],
  ["h2", h2Classes],
  ["h3", h3Classes],
  ["h4", h4Classes],
  ["h5", h5Classes],
  ["h6", h6Classes],
]);

export default defineComponent({
  name: "VHeading",
  props: {
    rank: {
      type: String as PropType<HeadingRank>,
      default: "h2",
    },
  },
  setup(props) {
    const className = computed(() => {
      let classes = `font-sans font-extrabold text-primary`;
      const rankClass = rankClasses.get(props.rank);

      return `${classes}${rankClass ? ` ${rankClass}` : ``}`;
    });

    return {
      className,
    };
  },
});
