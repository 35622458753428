
import { aimService } from "@/services/AIMService";

type StateWithRedirectPathParameter = {
  redirectPath: string | undefined;
};

aimService
  .signinRedirectCallback()
  .then(function (user) {
    const redirectPath = user.state
      ? (user.state as StateWithRedirectPathParameter).redirectPath
      : undefined;
    window.location.href = redirectPath ? redirectPath : "../home";
  })
  .catch(function (err) {
    err;
    // console.dir(err);
  });

export default {
  name: "sso-callback",
};
