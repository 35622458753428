import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

import { State, state } from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

// The key is to access the `useStore` function in Composition API in TypeScript
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  // modules: {},
});

// Defining a custom `useStore` function to avoid importing the key in every component
// https://vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function
export function useStore() {
  return baseUseStore(key);
}
