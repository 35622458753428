import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "rounded-xl border-4 border-transparent bg-gray-100 text-primary text-center flex flex-col items-center justify-center gap-y-4 px-6 py-12 outline-none hover:bg-gray-200 hover:border-primary focus-visible:border-secondary"
}
const _hoisted_2 = { class: "block font-extrabold text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_Icon, { icon: _ctx.iconKey }, null, 8, ["icon"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getLocalizedString(_ctx.questionnaire.surveyModel.title)), 1)
  ]))
}