import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, _mergeProps({ to: _ctx.toPage }, _ctx.$props, {
    class: _ctx.className,
    "aria-current": _ctx.current
  }), {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["to", "class", "aria-current"]))
}