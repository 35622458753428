import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_load_spinner = _resolveComponent("v-load-spinner")!
  const _component_v_section = _resolveComponent("v-section")!

  return (_openBlock(), _createBlock(_component_v_section, null, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_v_load_spinner, {
            key: 0,
            text: _ctx.loadingText
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}