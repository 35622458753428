
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "VLogo",
  components: { RouterLink },
  props: {
    color: {
      type: String as PropType<"white" | "primary">,
      default: () => "white",
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const fill = props.color === "white" ? "text-white" : "text-primary";

    return {
      t,
      fill,
    };
  },
});
