
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import VLogo from "@/components/atoms/VLogo/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";

export default defineComponent({
  name: "VFooter",
  components: { VButton, VLogo },
  setup() {
    const { t } = useI18n({ useScope: "global" });

    interface LinkList {
      label: string;
      links: Array<Link>;
    }

    interface Link {
      label: string;
      href: string;
    }

    const linkLists: Array<LinkList> = [
      {
        label: "resources",
        links: [
          {
            label: "naviGov",
            href: "https://www.navigov.com/",
          },
          {
            label: "helpDesk",
            href: "https://support.navigov.com/",
          },
          {
            label: "naviBlog",
            href: "https://www.navigov.com/blog",
          },
        ],
      },
    ];

    return {
      t,
      linkLists,
    };
  },
});
