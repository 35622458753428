
import { defineComponent, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import VSection from "@/components/atoms/VSection/index.vue";

import SurveyJSContainer from "@/components/organisms/SurveyJSContainer/index.vue";

import { useStore } from "@/store";
import { IndicatorOutput, Questionnaire } from "@/types/objects";
import { aimService } from "@/services/AIMService";

export default defineComponent({
  name: "QuestionnaireView",
  components: { SurveyJSContainer, VSection },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const activeResults = computed(() => store.getters.activeResults);

    const currentQuestionnaire: Questionnaire =
      store.getters.activeQuestionnaire;
    const onComplete = async (results: JSON) => {
      const user = await aimService.getUser();
      store.dispatch("saveResultsToApi", {
        results: results,
        username: user?.profile.username,
        email: user?.profile.email,
      });
    };

    watch(activeResults, (curr: IndicatorOutput) => {
      router.push(`/results/${curr.uuid}`);
    });

    return {
      t,
      currentQuestionnaire,
      onComplete,
    };
  },
});
