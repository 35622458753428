
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { aimService } from "@/services/AIMService";

import VLoadSpinner from "@/components/organisms/VLoadSpinner/index.vue";
import VSection from "@/components/atoms/VSection/index.vue";
import VHeading from "@/components/atoms/VHeading/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";
import VResultButton from "@/components/molecules/VResultsButton/index.vue";
import VInput from "@/components/atoms/VInput/index.vue";
import VSwitch from "@/components/atoms/VSwitch/index.vue";

import { UserResultReference } from "@/types/objects";

export default defineComponent({
  name: "AdminAllResultsView",
  components: {
    VSection,
    VHeading,
    VLoadSpinner,
    VButton,
    VResultButton,
    VInput,
    VSwitch,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    const store = useStore();
    const router = useRouter();

    const isLoading = ref(false);
    const error = ref();

    const results = ref<UserResultReference[]>();
    const searchStr = ref("");
    const isInterimFilter = ref(false);

    const isLoggedIn = async () => {
      return await aimService.isLoggedIn();
    };

    const getAllResults = async () => {
      isLoading.value = true;
      if (await isLoggedIn()) {
        try {
          await store.dispatch("loadAllResultReferences");
          results.value = store.state.results.sort((a, b) => {
            return (
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
            );
          });
          isLoading.value = false;
        } catch (e) {
          error.value = e;
          isLoading.value = false;
        }
      }
    };

    const toResult = async (result: UserResultReference) => {
      await store.dispatch("updateActiveResultUUID", result.uuid);
      await router.push(`/results/${result.uuid}`);
    };

    const getLocalizedString = (
      input: Record<string, string> | string
    ): string => {
      if (typeof input === "string") {
        return input;
      } else if (input instanceof Object) {
        if (
          Object.prototype.hasOwnProperty.call(input, locale.value as string)
        ) {
          return input[locale.value as string];
        } else {
          return input["default"] ?? ""; //"default" is english
        }
      } else {
        return "";
      }
    };

    const filteredResults = computed((): UserResultReference[] => {
      if (results.value) {
        return results.value?.filter((result: UserResultReference) => {
          const questionnaireTitleClause = getLocalizedString(
            result.questionnaireTitle
          )
            .toLowerCase()
            .includes(searchStr.value.toLowerCase());
          const userTitleClause = result.userTitle
            ? result.userTitle
                .toLowerCase()
                .includes(searchStr.value.toLowerCase())
            : false;
          const emailClause = result.email
            .toLowerCase()
            .includes(searchStr.value.toLowerCase());
          return (
            (questionnaireTitleClause || userTitleClause || emailClause) &&
            (isInterimFilter.value ? result.isInterim : true)
          );
        });
      } else {
        return [];
      }
    });

    onMounted(() => {
      store.dispatch("removeAllResults");
      getAllResults();
    });

    return {
      t,
      isLoading,
      error,
      loadingText: computed(() => t("loadingResults")),
      getAllResults,
      filteredResults,
      toResult,
      searchStr,
      isInterimFilter,
    };
  },
});
