
import { defineComponent, PropType, computed } from "vue";
import type { ComponentSize, ComponentColor, ButtonVariant } from "@/types/ui";

export default defineComponent({
  name: "VButton",
  props: {
    variant: {
      type: String as PropType<ButtonVariant>,
      default: () => "solid",
    },
    size: {
      type: String as PropType<ComponentSize>,
      default: () => "md",
    },
    color: {
      type: String as PropType<ComponentColor>,
      default: () => "primary",
    },
    label: {
      type: String,
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    const className = computed(() => {
      let classes = `tracking-wider rounded-lg border-2 inline-flex items-center justify-center space-x-2 font-bold focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-60`;

      if (props.variant !== "link") {
        switch (props.color) {
          case "primary":
            classes = `${classes} border-primary`;
            break;
          case "muted":
            classes = `${classes} border-gray-300`;
            break;
          case "light":
            classes = `${classes} border-white`;
            break;
          case "dark":
            classes = `${classes} border-gray-600`;
            break;
        }
      } else {
        classes = `${classes} border-transparent`;
      }

      if (props.variant === "solid") {
        switch (props.color) {
          case "primary":
            classes = `${classes} bg-primary text-white hover:bg-white hover:text-primary`;
            break;
          case "muted":
            classes = `${classes} bg-gray-300 text-gray-900 hover:bg-gray-50`;
            break;
          case "light":
            classes = `${classes} bg-white text-primary hover:bg-gray-100`;
            break;
          case "dark":
            classes = `${classes} bg-gray-600 text-white hover:bg-gray-700`;
            break;
        }
      } else {
        classes = `${classes} bg-transparent`;

        switch (props.color) {
          case "primary":
            classes = `${classes} text-primary hover:bg-primary hover:text-white`;
            break;
          case "muted":
            classes = `${classes} text-gray-500 hover:bg-gray-300 hover:text-gray-900`;
            break;
          case "light":
            classes = `${classes} text-white hover:bg-white hover:text-primary`;
            break;
          case "dark":
            classes = `${classes} text-gray-600 hover:bg-gray-600 hover:text-white`;
            break;
        }
      }

      switch (props.size) {
        case "xs":
          classes = `${classes} text-xs py-1 px-2`;
          break;
        case "sm":
          classes = `${classes} text-sm py-1 px-2`;
          break;
        case "md":
          classes = `${classes} py-2 px-4`;
          break;
        case "lg":
          classes = `${classes} text-lg py-3 px-4`;
          break;
        case "xl":
          classes = `${classes} text-xl py-3 px-4`;
          break;
      }

      return classes;
    });

    return {
      className,
      onClick() {
        emit("click");
      },
    };
  },
});
