
import { defineComponent, ref, Ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import VSection from "@/components/atoms/VSection/index.vue";

import SurveyJSContainer from "@/components/organisms/SurveyJSContainer/index.vue";
import VLoadSpinner from "@/components/organisms/VLoadSpinner/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";

import { useStore } from "@/store";
import { Questionnaire } from "@/types/objects";

export default defineComponent({
  name: "ReadOnlyQuestionnaireView",
  components: { SurveyJSContainer, VSection, VLoadSpinner, VButton },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const resultsUuid: string | string[] = route.params.id;
    const isLoading = ref(false);
    const error = ref();

    const answers: Ref<Record<string, unknown>> = ref({});
    const currentQuestionnaire: Ref<Questionnaire | undefined> = ref();

    const toResults = () => {
      router.push(`/results/${resultsUuid}`);
    };

    const getSurveyAndResults = async () => {
      isLoading.value = true;
      try {
        await store.dispatch("loadResultsFromApi", resultsUuid);
        await store.dispatch("loadResultAnswers", resultsUuid);
        answers.value = store.getters.activeResultAnswers;
        await store.dispatch("loadQuestionnaireFromResult", resultsUuid);
        currentQuestionnaire.value = store.getters.activeQuestionnaire;
      } catch (e) {
        error.value = e;
        isLoading.value = false;
      }
      if (answers.value && currentQuestionnaire.value) {
        isLoading.value = false;
      } else {
        error.value = "Error loading the questionnaire answers";
        isLoading.value = false;
      }
    };

    onMounted(() => {
      store.dispatch("removeResultAnswer");
      store.dispatch("removeActiveQuestionnaire");

      getSurveyAndResults();
    });

    return {
      t,
      isLoading,
      loadingText: computed(() => t("loadingResults")),
      error,
      getSurveyAndResults,
      currentQuestionnaire,
      answers,
      toResults,
    };
  },
});
