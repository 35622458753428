
import { defineComponent } from "vue";

export default defineComponent({
  name: "VLoadSpinner",
  props: {
    active: Boolean,
    text: String,
  },
  setup() {
    return {};
  },
});
