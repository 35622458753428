import { IndicatorOutput, Questionnaire } from "@/types/objects";
import { State } from "./state";

export default {
  id(state: State): string {
    return state.id;
  },
  ///////////////////
  // Questionnaire //
  ///////////////////
  questionnairesIsEmpty(state: State): boolean {
    return state.questionnaires.length == 0;
  },
  activeQuestionnaire(state: State): Questionnaire | undefined {
    return state.activeQuestionnaire;
  },

  /////////////
  // Results //
  /////////////
  resultsIsEmpty(state: State): boolean {
    return state.results.length == 0;
  },
  activeResults(state: State): IndicatorOutput | undefined {
    return state.activeResults;
  },
  activeResultAnswers(state: State): Record<string, unknown> | undefined {
    return state.activeResultAnswers;
  },
};
