
import { User } from "@/types/objects";
import { defineComponent, PropType, computed } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "VUserButton",
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    intractable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const cssClass = computed(() => {
      return `rounded-xl border-4 border-transparent bg-gray-100 text-primary text-center flex flex-col items-center justify-between gap-y-4 p-6 outline-none md:flex-row ${
        props.intractable
          ? "hover:bg-gray-200 hover:border-primary focus-visible:border-secondary"
          : "cursor-default"
      }`;
    });
    return {
      t,
      cssClass,
    };
  },
});
