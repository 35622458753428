import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 100 100",
  class: "w-24 h-24"
}
const _hoisted_2 = {
  key: 0,
  fill: "#44D55B",
  d: "M49 81c17.675 0 32-14.325 32-32S66.675 17 49 17 17 31.325 17 49s14.325 32 32 32ZM37.513 57.687C39.75 60.276 43.575 63 49 63s9.25-2.725 11.487-5.313a2 2 0 0 1 2.825-.2 2 2 0 0 1 .2 2.825C60.726 63.513 55.889 67 49 67c-6.888 0-11.725-3.487-14.513-6.688a2 2 0 0 1 .2-2.825 2 2 0 0 1 2.826.2ZM43.05 43c0 2.212-1.787 4-4 4-2.212 0-4-1.788-4-4 0-2.212 1.788-4 4-4 2.213 0 4 1.788 4 4Zm16 4c-2.212 0-4-1.788-4-4 0-2.212 1.788-4 4-4 2.213 0 4 1.788 4 4 0 2.212-1.787 4-4 4Z"
}
const _hoisted_3 = {
  key: 1,
  fill: "#FCA311",
  d: "M81 49c0 17.675-14.325 32-32 32S17 66.675 17 49s14.325-32 32-32 32 14.325 32 32ZM39 65c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2s-.9-2-2-2H41c-1.1 0-2 .9-2 2Zm-2-11c4.975 0 9-4.025 9-9s-4.025-9-9-9-9 4.025-9 9 4.025 9 9 9Zm33-9c0-4.975-4.025-9-9-9s-9 4.025-9 9 4.025 9 9 9 9-4.025 9-9Zm-30 0c0 1.663-1.337 3-3 3s-3-1.337-3-3 1.337-3 3-3 3 1.337 3 3Zm24 0c0 1.663-1.337 3-3 3s-3-1.337-3-3 1.337-3 3-3 3 1.337 3 3Z"
}
const _hoisted_4 = {
  key: 2,
  fill: "#EF3D3D",
  d: "M50 82c17.675 0 32-14.325 32-32S67.675 18 50 18 18 32.325 18 50s14.325 32 32 32ZM38.587 59.087C41.337 56.337 45.325 54 50 54s8.663 2.337 11.413 5.087c1.387 1.388 2.512 2.925 3.3 4.425C65.488 64.975 66 66.563 66 68c0 .65-.325 1.275-.862 1.65a1.967 1.967 0 0 1-1.838.225l-2.563-.963A30.002 30.002 0 0 0 50.2 67h-.4c-3.6 0-7.162.65-10.537 1.912l-2.563.963c-.613.225-1.3.15-1.838-.225A2.01 2.01 0 0 1 34 68c0-1.45.525-3.025 1.288-4.487.787-1.5 1.912-3.038 3.3-4.425Zm-3.9-22.75 11.238 5.988c1.338.712 1.338 2.637 0 3.35l-11.237 5.988c-.988.525-2.188-.188-2.188-1.313 0-.35.125-.688.35-.95l4.5-5.4-4.5-5.4a1.46 1.46 0 0 1-.35-.95c0-1.125 1.2-1.837 2.188-1.313Zm32.813 1.3c0 .35-.125.688-.35.95l-4.5 5.4 4.5 5.4c.225.263.35.6.35.95 0 1.125-1.2 1.838-2.188 1.313l-11.237-5.987c-1.338-.713-1.338-2.638 0-3.35l11.237-5.988c.988-.525 2.188.188 2.188 1.313Z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_ctx.icon === 3)
      ? (_openBlock(), _createElementBlock("path", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.icon === 2)
      ? (_openBlock(), _createElementBlock("path", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.icon === 1)
      ? (_openBlock(), _createElementBlock("path", _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}