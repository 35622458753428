
import { UserResultReference } from "@/types/objects";
import { defineComponent, PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import { isDate, format } from "date-fns";

export default defineComponent({
  name: "VResultButton",
  props: {
    result: {
      type: Object as PropType<UserResultReference>,
      required: true,
    },
  },
  setup(props) {
    const { t, locale } = useI18n({ useScope: "global" });
    const getLocalizedString = (input: Record<string, unknown> | string) => {
      if (typeof input === "string") {
        return input;
      } else if (input instanceof Object) {
        if (
          Object.prototype.hasOwnProperty.call(input, locale.value as string)
        ) {
          return input[locale.value as string];
        } else {
          return input["default"]; //"default" is english
        }
      }
    };

    const hasUserTitle = computed(() => {
      return props.result.userTitle && props.result.userTitle != " - undefined";
    });

    const creationDate = computed(() => {
      const parsedDate = new Date(props.result.creationDate);
      // Unable to parse date so just return it
      if (!isDate(parsedDate)) {
        console.log("not a date");
        return props.result.creationDate;
      }

      return format(parsedDate, "PPP p");
    });

    return {
      t,
      getLocalizedString,
      creationDate,
      hasUserTitle,
    };
  },
});
