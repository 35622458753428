import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 2,
  class: "space-y-5 flex flex-col"
}
const _hoisted_3 = {
  id: "search-bar",
  class: "flex flex-row items-center space-x-4"
}
const _hoisted_4 = {
  id: "interim-toggle",
  class: "flex flex-row justify-center text-left space-x-3 shrink-0 text-lg"
}
const _hoisted_5 = { class: "flex flex-col space-y-3 my-4 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_load_spinner = _resolveComponent("v-load-spinner")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_VHeading = _resolveComponent("VHeading")!
  const _component_v_input = _resolveComponent("v-input")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_result_button = _resolveComponent("v-result-button")!
  const _component_v_section = _resolveComponent("v-section")!

  return (_openBlock(), _createBlock(_component_v_section, null, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_v_load_spinner, {
            key: 0,
            text: _ctx.loadingText
          }, null, 8, ["text"]))
        : (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.t("loadingResultsFailed")) + " ", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.error), 1),
              _createVNode(_component_v_button, { onClick: _ctx.getAllResults }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("tryAgain")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_VHeading, { rank: "h1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("allResults")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_input, {
                  id: "searchBar",
                  "aria-label": "Search Results",
                  class: "grow",
                  type: "text",
                  name: "resultsSearch",
                  placeholder: _ctx.t('searchResults'),
                  modelValue: _ctx.searchStr,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchStr) = $event))
                }, null, 8, ["placeholder", "modelValue"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_v_switch, {
                    id: "interimToggle",
                    name: "interimToggle",
                    modelValue: _ctx.isInterimFilter,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isInterimFilter) = $event)),
                    label: _ctx.t('showOnlyInterim')
                  }, null, 8, ["modelValue", "label"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredResults, (result) => {
                  return (_openBlock(), _createBlock(_component_v_result_button, {
                    key: result.uuid,
                    onClick: ($event: any) => (_ctx.toResult(result)),
                    result: result
                  }, null, 8, ["onClick", "result"]))
                }), 128))
              ])
            ]))
    ]),
    _: 1
  }))
}