import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen flex flex-col" }
const _hoisted_2 = { id: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_header = _resolveComponent("v-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_header),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_v_footer)
  ], 64))
}