
import "survey-core/defaultV2.min.css";
import "./survey.css";
import { Model, StylesManager } from "survey-jquery";
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { Questionnaire } from "@/types/objects";
import JQuery from "jquery";

StylesManager.applyTheme("defaultV2");

export default defineComponent({
  name: "SurveyJSContainer",
  props: {
    questionnaire: Questionnaire,
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    answers: {
      type: Object,
      default: null,
    },
  },
  emits: ["onComplete"],
  setup(props, { emit }) {
    const { t, locale } = useI18n({ useScope: "global" });

    const surveyObj = new Model(props.questionnaire?.surveyModel);
    surveyObj.locale = locale.value as string;
    surveyObj.mode = props.isReadOnly ? "display" : "edit";
    surveyObj.data = props.answers ? props.answers : {};
    surveyObj.onComplete.add(() => emit("onComplete", surveyObj.data));
    onMounted(() => {
      surveyObj.render(JQuery("#surveyContainer")[0]);
    });
    return { t };
  },
});
