
import { defineComponent, ref, watch, WritableComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { languages } from "../../../i18n";

type Locale = "en" | "fr";

export default defineComponent({
  name: "VLocaleSwitcher",
  setup() {
    const { t, locale } = useI18n<
      Record<string, string>,
      WritableComputedRef<Locale>
    >({
      useScope: "global",
    });
    const languageArray = ref(languages);

    watch(locale, async (newLocale) => {
      document.documentElement.setAttribute("lang", newLocale);
    });

    const title = t("languageSelector");

    return {
      t,
      locale,
      languageArray,
      title,
    };
  },
});
