
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import VSection from "@/components/atoms/VSection/index.vue";

export default defineComponent({
  name: "WelcomeView",
  components: { VSection },
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
