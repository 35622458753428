
import { defineComponent } from "vue";
import VButton from "../../atoms/VButton/index.vue";

export default defineComponent({
  name: "VModal",
  components: { VButton },
  props: {
    modalActive: {
      type: Boolean,
      required: true,
    },
    showSaveButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit("close");
    };
    const saveData = () => {
      emit("saveData");
    };
    return {
      closeModal,
      saveData,
    };
  },
});
