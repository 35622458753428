
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { aimService } from "@/services/AIMService";

import VLoadSpinner from "@/components/organisms/VLoadSpinner/index.vue";
import VSection from "@/components/atoms/VSection/index.vue";
import VHeading from "@/components/atoms/VHeading/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";
import VResultButton from "@/components/molecules/VResultsButton/index.vue";

import { UserResultReference } from "@/types/objects";

export default defineComponent({
  name: "UserResultsView",
  components: {
    VSection,
    VHeading,
    VLoadSpinner,
    VButton,
    VResultButton,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const isLoading = ref(false);
    const error = ref();

    const results = ref<UserResultReference[]>();

    const isLoggedIn = async () => {
      return await aimService.isLoggedIn();
    };

    const getUserResults = async () => {
      isLoading.value = true;
      if (await isLoggedIn()) {
        try {
          const user = await aimService.getUser();
          await store.dispatch("loadAllUserResults", user?.profile.username);
          results.value = store.state.results.sort((a, b) => {
            return (
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
            );
          });
          isLoading.value = false;
        } catch (e) {
          error.value = e;
          isLoading.value = false;
        }
      }
    };

    const toResult = async (result: UserResultReference) => {
      await store.dispatch("updateActiveResultUUID", result.uuid);
      await router.push(`/results/${result.uuid}`);
    };

    onMounted(() => {
      store.dispatch("removeAllResults");
      getUserResults();
    });

    return {
      t,
      isLoading,
      error,
      loadingText: computed(() => t("loadingUserResults")),
      getUserResults,
      results,
      toResult,
    };
  },
});
