
import { defineComponent, onMounted } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";

import { aimService } from "./services/AIMService";
import VHeader from "./components/organisms/VHeader/index.vue";
import VFooter from "./components/organisms/VFooter/index.vue";

export default defineComponent({
  name: "App",
  components: { VHeader, VFooter },
  setup() {
    const { t, locale } = useI18n();

    axios.interceptors.request.use(async (config: any) => {
      if (await aimService.isLoggedIn()) {
        const accessToken = await aimService.getRawAccessToken();
        config.headers.common.Authorization = "Bearer " + accessToken;
      }

      return config;
    });
    onMounted(() => {
      document.title = t("appTitle");
      document.documentElement.setAttribute("lang", locale.value);
    });
    return {};
  },
});
