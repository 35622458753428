
import { defineComponent, computed } from "vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "VAppLink",
  components: { RouterLink },
  props: {
    label: {
      type: String,
    },
    isActive: {
      type: Boolean,
    },
    toPage: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // const className = computed(
    //   () =>
    //     `${
    //       props.isActive ? `bg-gray-900 text-primary` : `bg-primary`
    //     } py-2 px-4 tracking-wider rounded-lg bg-opacity-60 hover:bg-white hover:text-primary focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-60`
    // );
    const className = computed(() => {
      let classes = `tracking-wider rounded-lg py-2 px-4 border-2 border-transparent inline-flex items-center justify-center space-x-2 font-bold hover:bg-white hover:text-primary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-60`;

      if (props.isActive) {
        classes = `${classes} bg-white text-primary`;
      }

      return classes;
    });

    const current = computed(() => {
      return props.isActive ? "page" : undefined;
    });

    return {
      className,
      current,
    };
  },
});
