import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 100 100",
  class: "w-24 h-24"
}
const _hoisted_2 = {
  key: 0,
  fill: "currentColor",
  d: "M76.25 50c0-14.492-11.758-26.25-26.25-26.25a4.37 4.37 0 0 1-4.375-4.375A4.37 4.37 0 0 1 50 15c19.332 0 35 15.668 35 35S69.332 85 50 85a4.37 4.37 0 0 1-4.375-4.375A4.37 4.37 0 0 1 50 76.25c14.492 0 26.25-11.758 26.25-26.25ZM46.527 31.598 61.84 46.91a4.382 4.382 0 0 1 0 6.194L46.527 68.415a4.382 4.382 0 0 1-6.193 0 4.382 4.382 0 0 1 0-6.193l7.848-7.848H19.375A4.37 4.37 0 0 1 15 50a4.37 4.37 0 0 1 4.375-4.375h28.807l-7.848-7.848a4.382 4.382 0 0 1 0-6.193 4.382 4.382 0 0 1 6.193 0v.014Z"
}
const _hoisted_3 = {
  key: 1,
  fill: "currentColor",
  d: "M59.256 19.152c-2.046-.594-4.175.605-4.765 2.664l-15.4 54.25a3.882 3.882 0 0 0 2.647 4.795c2.046.594 4.175-.605 4.765-2.664l15.4-54.25a3.882 3.882 0 0 0-2.647-4.795Zm9.697 14.543a3.899 3.899 0 0 0 0 5.486l10.744 10.826L68.94 60.832a3.899 3.899 0 0 0 0 5.486 3.838 3.838 0 0 0 5.45 0l13.475-13.563a3.899 3.899 0 0 0 0-5.485L74.391 33.707a3.838 3.838 0 0 0-5.45 0l.012-.012Zm-36.9 0a3.838 3.838 0 0 0-5.45 0L13.128 47.258a3.899 3.899 0 0 0 0 5.485l13.475 13.563a3.838 3.838 0 0 0 5.45 0 3.899 3.899 0 0 0 0-5.486L21.297 50.007 32.053 39.18a3.899 3.899 0 0 0 0-5.486Z"
}
const _hoisted_4 = {
  key: 2,
  fill: "currentColor",
  d: "M49.5 27.5c5.229 0 9.464 4.195 9.464 9.375V42.5H40.036v-5.625c0-5.18 4.235-9.375 9.464-9.375Zm-17.036 9.375V42.5h-1.893C26.395 42.5 23 45.863 23 50v22.5c0 4.137 3.395 7.5 7.571 7.5H68.43C72.605 80 76 76.637 76 72.5V50c0-4.137-3.395-7.5-7.571-7.5h-1.893v-5.625C66.536 27.559 58.906 20 49.5 20c-9.405 0-17.036 7.559-17.036 16.875ZM53.286 57.5V65c0 2.074-1.692 3.75-3.786 3.75-2.094 0-3.786-1.676-3.786-3.75v-7.5c0-2.074 1.692-3.75 3.786-3.75 2.094 0 3.786 1.676 3.786 3.75Z"
}
const _hoisted_5 = {
  key: 3,
  fill: "currentColor",
  d: "M36.547 22.797a3.093 3.093 0 0 1 .231 4.37l-9.25 10.313a3.028 3.028 0 0 1-2.21 1.018 3.147 3.147 0 0 1-2.26-.902l-5.152-5.157a3.088 3.088 0 0 1 0-4.37c1.207-1.198 3.16-1.211 4.355 0l2.84 2.85 7.078-7.89a3.068 3.068 0 0 1 4.355-.232h.013Zm0 20.625a3.093 3.093 0 0 1 .231 4.37l-9.25 10.313a3.028 3.028 0 0 1-2.21 1.018 3.147 3.147 0 0 1-2.26-.902l-5.152-5.157a3.088 3.088 0 0 1 0-4.37c1.207-1.198 3.16-1.211 4.355 0l2.84 2.85 7.078-7.89a3.068 3.068 0 0 1 4.355-.232h.013Zm9.237-13.174a4.114 4.114 0 0 1 4.111-4.125h28.778a4.114 4.114 0 0 1 4.111 4.125 4.114 4.114 0 0 1-4.11 4.125H49.894a4.114 4.114 0 0 1-4.11-4.125Zm0 20.625a4.114 4.114 0 0 1 4.111-4.125h28.778a4.114 4.114 0 0 1 4.111 4.125 4.114 4.114 0 0 1-4.11 4.125H49.894a4.114 4.114 0 0 1-4.11-4.125Zm-8.222 20.625a4.114 4.114 0 0 1 4.111-4.125h37a4.114 4.114 0 0 1 4.111 4.125 4.114 4.114 0 0 1-4.11 4.125h-37a4.114 4.114 0 0 1-4.112-4.125Zm-14.389 6.187c-3.404 0-6.167-2.771-6.167-6.187 0-3.416 2.763-6.188 6.167-6.188 3.405 0 6.167 2.772 6.167 6.188s-2.762 6.187-6.167 6.187Z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_ctx.icon === 'migrate')
      ? (_openBlock(), _createElementBlock("path", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.icon === 'build')
      ? (_openBlock(), _createElementBlock("path", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.icon === 'security')
      ? (_openBlock(), _createElementBlock("path", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.icon === 'tutorial')
      ? (_openBlock(), _createElementBlock("path", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}