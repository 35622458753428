import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 2,
  class: "space-y-5"
}
const _hoisted_3 = { class: "grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_load_spinner = _resolveComponent("v-load-spinner")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_VHeading = _resolveComponent("VHeading")!
  const _component_v_questionnaire_button = _resolveComponent("v-questionnaire-button")!
  const _component_v_section = _resolveComponent("v-section")!

  return (_openBlock(), _createBlock(_component_v_section, null, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_v_load_spinner, {
            key: 0,
            text: _ctx.loadingText
          }, null, 8, ["text"]))
        : (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.t("loadingQuestionnairesFailed")) + " ", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.error), 1),
              _createVNode(_component_v_button, { onClick: _ctx.getQuestionnaires }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("tryAgain")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_VHeading, { rank: "h1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("homeGreeting")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionnaires, (questionnaire) => {
                  return (_openBlock(), _createBlock(_component_v_questionnaire_button, {
                    key: questionnaire.uuid,
                    onClick: ($event: any) => (_ctx.toQuestionnaireStart(questionnaire)),
                    questionnaire: questionnaire
                  }, null, 8, ["onClick", "questionnaire"]))
                }), 128))
              ])
            ]))
    ]),
    _: 1
  }))
}