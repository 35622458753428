
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

import { Questionnaire } from "@/types/objects";

import Icon, { type IconKey } from "./Icon.vue";

const iconKeyMap = new Map<string, IconKey>([
  ["1", "migrate"],
  ["2", "build"],
  ["3", "security"],
  ["4", "tutorial"],
]);

export default defineComponent({
  name: "VQuestionnaireButton",
  components: { Icon },
  props: {
    questionnaire: {
      type: Object as PropType<Questionnaire>,
      required: true,
    },
  },
  setup(props) {
    const { locale } = useI18n({ useScope: "global" });
    const getLocalizedString = (input: Record<string, unknown> | string) => {
      if (typeof input === "string") {
        return input;
      } else if (input instanceof Object) {
        if (
          Object.prototype.hasOwnProperty.call(input, locale.value as string)
        ) {
          return input[locale.value as string];
        } else {
          return input["default"]; //"default" is english
        }
      }
    };

    const iconKey = computed(() => {
      return iconKeyMap.get(props.questionnaire.uuid) ?? "build";
    });

    return {
      getLocalizedString,
      iconKey,
    };
  },
});
