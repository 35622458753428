import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "rounded-xl border-4 border-transparent bg-gray-100 text-primary text-center flex flex-col items-center justify-between gap-y-4 p-6 outline-none hover:bg-gray-200 hover:border-primary focus-visible:border-secondary md:flex-row"
}
const _hoisted_2 = { id: "title_username" }
const _hoisted_3 = {
  id: "title",
  class: "font-bold text-lg"
}
const _hoisted_4 = {
  id: "username",
  class: "flex justify-start"
}
const _hoisted_5 = { id: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getLocalizedString(_ctx.result.questionnaireTitle)) + " " + _toDisplayString(_ctx.hasUserTitle ? (_ctx.result.userTitle ? ` - ${_ctx.result.userTitle}` : "") : "") + " " + _toDisplayString(_ctx.result.isInterim ? ` - ${_ctx.t("interimResults")}` : ""), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.result.email), 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.creationDate), 1)
  ]))
}