
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { languages } from "../../../i18n";
import { aimService } from "@/services/AIMService";

import VLogo from "@/components/atoms/VLogo/index.vue";
import VButton from "@/components/atoms/VButton/index.vue";
// import VNavLink from "@/components/atoms/VNavLink/index.vue";
import VAppLink from "@/components/atoms/VAppLink/index.vue";
import VLocaleSwitcher from "@/components/molecules/VLocaleSwitcher/index.vue";

export default defineComponent({
  name: "VHeader",
  components: { VButton, /*VNavLink,*/ VAppLink, VLogo, VLocaleSwitcher },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const languageArray = ref(languages);
    const isLoggedIn = ref(false);
    const isAdmin = ref(false);
    const fullName = ref("");

    const login = (): void => {
      aimService.login();
    };

    const logout = (): void => {
      aimService.logout();
    };

    onMounted(async () => {
      aimService.getUser().then((user) => {
        // TODO get proper profile data (settings in AWS)
        // if (user != null && user.profile != null && user.profile.name != null) {
        //   fullName.value = user.profile.name;
        // }
        isLoggedIn.value = user !== null && !user.expired;
        // isLoggedIn.value = true;
      });
      // TODO implement roles
      isAdmin.value = await aimService.isAdmin();
      // await aimService.isAdmin().then((confirmedAdmin: boolean) => {
      //   isAdmin.value = confirmedAdmin;
      // });
    });

    return {
      t,
      locale,
      languageArray,
      isLoggedIn,
      isAdmin,
      fullName,
      login,
      logout,
    };
  },
});
