
import { defineComponent, PropType } from "vue";

export type IconKey = "migrate" | "build" | "security" | "tutorial";

export default defineComponent({
  name: "VIcon",
  props: {
    icon: String as PropType<IconKey>,
  },
});
