import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "py-3 bg-primary text-white font-sans mb-3" }
const _hoisted_2 = { class: "container space-y-6" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { class: "flex justify-end items-center space-x-3" }
const _hoisted_5 = {
  key: 0,
  "aria-label": "Main Navigation",
  class: "flex space-x-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VLogo = _resolveComponent("VLogo")!
  const _component_v_app_link = _resolveComponent("v-app-link")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_v_locale_switcher = _resolveComponent("v-locale-switcher")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VLogo, {
          color: "white",
          class: "mr-4"
        }),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("nav", _hoisted_5, [
                _createVNode(_component_v_app_link, {
                  "to-page": "/home",
                  variant: "link",
                  "is-active": _ctx.$route.path === '/home'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("home")), 1)
                  ]),
                  _: 1
                }, 8, ["is-active"]),
                _createVNode(_component_v_app_link, {
                  variant: "link",
                  toPage: "/userResults",
                  "is-active": _ctx.$route.path === '/userResults'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("prevResults")), 1)
                  ]),
                  _: 1
                }, 8, ["is-active"]),
                (_ctx.isAdmin)
                  ? (_openBlock(), _createBlock(_component_v_app_link, {
                      key: 0,
                      variant: "link",
                      toPage: "/adminAllResults",
                      "is-active": _ctx.$route.path === '/adminAllResults'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("allResults")), 1)
                      ]),
                      _: 1
                    }, 8, ["is-active"]))
                  : _createCommentVNode("", true),
                (_ctx.isAdmin)
                  ? (_openBlock(), _createBlock(_component_v_app_link, {
                      key: 1,
                      variant: "link",
                      toPage: "/adminUserManagement",
                      "is-active": _ctx.$route.path === '/adminUserManagement'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("users")), 1)
                      ]),
                      _: 1
                    }, 8, ["is-active"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_v_button, {
                key: 1,
                onClick: _ctx.login,
                variant: "link",
                color: "light"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("signIn")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_v_button, {
                key: 2,
                onClick: _ctx.logout,
                variant: "link",
                color: "light"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("signOut")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_locale_switcher)
        ])
      ])
    ])
  ]))
}